import React from "react"
import FrontmatterPageContext from "~context/FakePageContext"
import { Box, Button } from "@fastly/consistently"
import T from "~components/translator"

const FundamentalsNavButtons = () => {
  const page = React.useContext(FrontmatterPageContext)

  if (page["section_safe"] !== "fundamentals") return <></>

  if (page["myPrimary"]?.frontmatter?.navigation !== true) return <></>

  // also consider `space-evenly`

  return (
    <div style={{ marginTop: "1em", display: "flex", justifyContent: "space-between" }}>
      {page["previous"] ? (
        <Button icon="arrow" iconDirection="left" url={page["previous"]}>
          <T>nav-back</T>
        </Button>
      ) : (
        <span></span>
      )}
      {page["next"] ? (
        <Button icon="arrow" url={page["next"]}>
          <T>nav-next</T>
        </Button>
      ) : (
        <span></span>
      )}
    </div>
  )
}

export default FundamentalsNavButtons

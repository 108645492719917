import React from "react"
import T from "./translator"
import Alert from "./alert"
import DRY from "./dry"
import { Box } from "@fastly/consistently"
import Awesome from "~components/awesome"

import PartialLogStreamingDefaultFormat from "~partials/log-streaming-default-format.mdx"
import FundamentalsCDNProgressBar from "~components/fundamentals_cdn_progress_bar"
import FundamentalsIOProgressBar from "~components/fundamentals_io_progress_bar"

// export const = () => {
//   return ()
// }

export const LimitedAvailability = () => {
  return (
    <Alert important>
      <T>limited-availability</T>
    </Alert>
  )
}

export const BetaAvailability = () => {
  return (
    <Alert important>
      This information is part of a beta release. For additional details, read our [product and feature lifecycle](/products/fastly-product-lifecycle#beta) descriptions.
    </Alert>
  )
}

export const NextGenWafPrevious = () => {
  return (
    <Alert>
      <small><T>next-gen-waf-previously-offered</T></small>
    </Alert>
  )
}

export const GdprLoggingPrivacy = () => {
  return (
    <Alert important>
      <T>gdpr-logging-privacy</T>
    </Alert>
  )
}

export const GdprVclPrivacy = () => {
  return (
    <Alert important>
      <T>gdpr-vcl-privacy</T>
    </Alert>
  )
}

// api_devhub_banner.html
// to be used on a page that doesnt exist yet
export const APIDevhubBanner = () => {
  return (
    <Alert info>
      <strong>We've improved your API documentation experience!</strong> Check out the new home of our [API reference](https://developer.fastly.com/reference/api/) at the [Fastly Developer Hub](https://developer.fastly.com/).
    </Alert>
  )
}

// cate-logging.html
export const ComputeAtEdgeLogging = () => {
  return (
    <Alert tip>
      <T>cate-logging-review</T>
    </Alert>
  )
}

// cloud-security.html
export const CloudSecurity = () => {
  return (
    <Alert>
      <small><T>cloud-security</T></small>
    </Alert>
  )
}

// third-party-support.html
export const ThirdPartySupport = () => {
  return (
    <Alert note>
      <T>third-party-support</T>
    </Alert>
  )
}

// third-party-integrations.html
export const ThirdPartyIntegrations = () => {
  return (
    <Alert>
      <small><T>third-party-integrations</T></small>
    </Alert>
  )
}

// integrations-migrations-support.html
export const IntegrationsMigrationsSupport = () => {
  return (
    <Alert>
      <small><T>integrations-migrations-support</T></small>
    </Alert>
  )
}

// waf-2020-notice.html
export const Waf2020Notice = () => {
  return (
    <Alert important>
      <T>waf-eol-notice</T>
    </Alert>
  )
}

// waf-legacy-notice.html
export const WafLegacyNotice = () => {
  return (
    <Alert important>
      <T>waf-2020-notice</T>
    </Alert>
  )
}

// edge-privacy.html
export const EdgePrivacy = () => {
  return (
    <Alert important>
      <T>edge-privacy</T>
    </Alert>
  )
}

// log-streaming-default-format.html
export const LogStreamingDefaultFormat = () => {
  return (
    <PartialLogStreamingDefaultFormat />
  )
}

// log-streaming-endpoint-disabled.html
export const LogStreamingEndpointDisabled = () => {
  return (
    <T>log-streaming-endpoint-disabled</T>
  )
}

// verify-certificate-warning.html
export const VerifyCertificateWarning = () => {
  return (
    <Alert warning>
      <T>verify-certificate-warning</T>
    </Alert>
  )
}

// outbound-data-transfer-discount.html
export const OutboundDataTransferDiscount = () => {
  return (
    <Alert note>
      <DRY>outbound-data-transfer-discount</DRY>
    </Alert>
  )
}
// otfp-warning.html
export const OTFPWarning = () => {
  return (
    <Alert important>
      <T>otfp-warning</T>
    </Alert>
  )
}

// fastly-101.html
export const FundamentalsCDNProgressBox = () => {
  return (<>
    <Box backgroundColor="#e0fff3" border="1px solid #007a60" borderRadius="4px" p="3" mb="3">
      <FundamentalsCDNProgressBar /><br /><br />
      <T>fastly-cdn</T>
    </Box>
    </>
  )
}

// fastly-signup.html
export const FastlySignup = () => {
  return (
    <Box border="1px solid #af3354" borderRadius="4px" p="3" mb="3">
      <span style={{color:"#af3354", marginRight: "1em"}}><Awesome icon="graduation-cap" extras="2xl" /></span>
      <T>fastly-signup</T>
    </Box>
  )
}

// translation-ja.html
export const TranslationJA = () => {
  return (
    <>
      <br/>翻訳についての<a href="/translations">注意事項</a>
    </>
  )
}

// dry/fastly-io.html
export const FundamentalsIOProgressBox = () => {
  return (<>
    <Alert box="success">
      <FundamentalsIOProgressBar /><br /><br />
      <T>fastly-io</T>
    </Alert>
    </>
  )
}

// export const = () => {
//   return ()
// }

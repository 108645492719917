import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import FrontmatterPageContext from "~context/FakePageContext"

const FundamentalsCDNProgressBar = (props) => {

  const data = useStaticQuery(graphql`
    query FunCDNPB {
      guides_in_prime: allMdx(
        filter: {
          fileAbsolutePath: { regex: "//_en_fundamentals//" }
          frontmatter: { primary_nav: { eq: "fastly-cdn" } }
        }
      ) {
        totalCount
      }
    }
  `)

  const { totalCount } = data.guides_in_prime

  const page = React.useContext(FrontmatterPageContext);
  const { page_number } = page

  let fraction = Math.ceil((page_number / totalCount) * 100)
  let title = `${page_number} of ${totalCount}`
  return (
    <progress max="100" value={fraction} style={{ width: "100%", backgroundColor: "green" }} title={title}>{title}</progress>
  )
}

export default FundamentalsCDNProgressBar

import { Link } from "gatsby";
import React from "react"
import FrontmatterPageContext from "~context/FakePageContext"
import T from "~components/translator"

export const SeeAlsoInPage = () => {
  const page = React.useContext(FrontmatterPageContext);

  if( !('see_also' in page) ){
    return (<></>)
  }
  const { see_also } = page

  const header_text = page.see_also_header || (<T>additional-resources</T>) || 'Additional resources:'

  const see_also_links = see_also.map((also) => {
    return (
      <li>
        <Link to={also.url}>{also.text}</Link>
      </li>
    )
  })

  return (
    <>
      <hr />
      <header id="additional_resources">{header_text}</header>
      <ul>
        {see_also_links}
      </ul>
    </>
  )
}

export const SeeAlsoPatch = (old_style) => {
  return old_style.map((sa) => {
    sa.title = sa.text
    // unset(sa.text)
    return sa
  })
}

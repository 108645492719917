import React from "react"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { H1, OnThisPage, hooks, Box } from "@fastly/consistently"
import { renderToStaticMarkup } from "react-dom/server"

import { CodeBlock } from "~components/CodeBlock"
import Alert from "~components/alert"
import T from "~components/translator"
import mailto from "~components/mailto"
import Awesome from "~components/awesome"
import DRY from "~components/dry"
import Raw from "~components/raw"
import Frontmatter from "~components/frontmatter"
import { LimitedAvailBadge } from "~components/avail-badges"

import * as Includes from "~components/includes"
import FrontmatterPageContext from "~context/FakePageContext"
import LanguageDropdown from "~components/lang-dropdown"
import LastUpdatedDate from "~components/last-updated-wrapper"

import { BreadcrumbsGuides } from "~components/Breadcrumbs/Breadcrumbs"
import FeedbackFormKeep from "~components/feedback-formkeep"
import { SideMenuSwitcher } from "~components/SideMenu/side-menu"
import { SeeAlsoPatch } from "~components/see_also"
import FloatedImage from "~components/floated-image"

import { SeoHead } from "~components/Head/SeoHead"
import { FavHead } from "~components/Head/FavHead"
import LayoutCore from "./layout-core"
import { mainArea } from "~vanilla/layout-guide-article.css"
import { main } from "~vanilla/layout-guide-article.css"
import { sideMenu } from "~components/SideMenu/side-menu.css"

import { LoremIpsum } from "react-lorem-ipsum"
import FundamentalsNavButtons from "~components/fundamentals_buttons"
import { Link } from "gatsby"

const LayoutGuideArticle = ({ pageContext, location }) => {
  const { frontmatter, body } = pageContext
  const { tableOfContents = { items: [] } } = pageContext
  frontmatter["section_safe"] = frontmatter["section"] || "none"
  const { useIntersectionObserver } = hooks
  const { containerRef, activeEl } = useIntersectionObserver()

  // for MDXProvider later
  let mdx_components = {
    T,
    Alert,
    mailto,
    Awesome,
    ...Includes,
    DRY,
    Raw,
    Box,
    LoremIpsum,
    CodeBlock,
    pre: CodeBlock,
    FloatedImage,
    Frontmatter,
    LimitedAvailBadge,
    Link
  }

  const tocCount = countToc(tableOfContents)
  const showToc = (tocCount >= 3)

  return (
    <FrontmatterPageContext.Provider value={frontmatter}>
      <LayoutCore>
        <div className={`${mainArea} grid-main layout-guide_article`}>
          <div
            className={`${sideMenu} main-nav section-${frontmatter.section_safe}`}
            role="navigation"
            aria-label="Navigation between documents"
            data-swiftype-index="false"
          >
            <SideMenuSwitcher />
          </div>

          <main className={`${main} main-content section-${frontmatter.section_safe}`} role="main">
            <div className="content-crumb">
              <BreadcrumbsGuides />
            </div>

            <div className="content-lang">
              <LanguageDropdown location={location} />
            </div>

            <div className="content-title">
              <section className="rtitle" data-rv-add="true">
                <H1 my="0">{frontmatter.header}</H1>
              </section>
            </div>

            <div className="content-date">
              <LastUpdatedDate />
            </div>

            <div className="content-content" ref={containerRef}>
              <MDXProvider components={mdx_components}>
                <MDXRenderer>{body}</MDXRenderer>
              </MDXProvider>
            </div>

            <nav className="content-nav" aria-label="Navigation of page sections and resources">
              <div>
                {showToc ? (
                  <OnThisPage label={<T>on-this-page</T>} navContent={tableOfContents.items} activeEl={activeEl} />
                ) : null}

                {frontmatter?.see_also?.length !== undefined ? (
                  <OnThisPage
                    label={frontmatter["see_also_header"] || <T>additional-resources</T>}
                    navContent={SeeAlsoPatch(frontmatter.see_also)}
                  />
                ) : null}
              </div>
            </nav>

            <div className="content-after">
              <FundamentalsNavButtons />

              {frontmatter?.lang === "ja" && <Includes.TranslationJA />}

              <FeedbackFormKeep id="7a402cfe248d" pathname={location.pathname} />
            </div>
          </main>
        </div>
      </LayoutCore>
    </FrontmatterPageContext.Provider>
  )
}

export default LayoutGuideArticle

// @TODO large parts of this will need extracted into a generic "base head" unit, like www does
export const Head = (props) => {
  const { frontmatter } = props.pageContext
  const { pathname } = props.location

  const title_suffix = renderToStaticMarkup(
    <T noparse={true} lang={frontmatter.lang}>title-suffix-guides</T>
  )

  const full_title = `${frontmatter["header"]} | ${title_suffix}`

  let langlinks = ""
  if (frontmatter?.i18n) {
    langlinks = []
    langlinks.push(<link rel="alternate" hrefLang={frontmatter["lang"]} href={props.location.pathname} data-self="1" key={frontmatter['lang']} />)

    let re = new RegExp(`^/${frontmatter.lang}/`)
    Object.values(frontmatter.i18n).forEach((langkey) => {
      let langpath = pathname.replace(re, `/${langkey}/`)

      // @TODO: this needs to be a full absolute path, need to query sitemetadata etc
      langlinks.push(<link rel="alternate" hrefLang={langkey} href={langpath} key={langkey} />)
    })
  }

  const noindex =
    frontmatter?.sitemap == false || frontmatter?.unlisted == true ? (
      frontmatter?.forceindex ? (
        <></>
      ) : (
        <meta name="robots" content="noindex" />
      )
    ) : (
      <></>
    )

  return (
    <>
      <title>{full_title}</title>
      {langlinks}
      {noindex}
      <SeoHead frontmatter={frontmatter} {...props} />
      <FavHead />
    </>
  )
}

const countToc = (tocData) => {
  let count = 0

  if (Object(tocData).hasOwnProperty('items') && Array.isArray(tocData.items) && tocData.items.length) {
    tocData.items.forEach((el) => {
      count += 1
      if (el?.items?.length > 0) {
        count += countToc(el)
      }
    })
  }

  return count
}
